<template>
  <a-config-provider :theme="Theme">
    <a-textarea class="df-textarea" v-bind="$attrs" :size="size" />
  </a-config-provider>
</template>
<script setup lang="ts">
import type { SizeType } from 'ant-design-vue/es/config-provider';
defineOptions({
  inheritAttrs: false,
});
type PropType = {
  size?: SizeType;
};
const props = withDefaults(defineProps<PropType>(), {
  size: 'large',
});
const padding = computed(() => {
  const small = '4.5px 6.5px';
  const middle = '8.5px 10.5px';
  const large = '10px 12px';
  switch (props.size) {
    case 'small':
      return small;
    case 'middle':
      return middle;
    default:
      return large;
  }
});
const Theme = {
  components: {
    Input: {
      colorTextPlaceholder: '#848B96', // 输入框placeholder文字颜色
      colorText: '#09090b', // 输入框文字颜色
      colorBorder: 'transparent', // 输入框边框颜色
      colorBgContainer: '#f0f0f5', // 输入框背景颜色
      controlOutlineWidth: 0, // 输入框聚焦时的box-shadow宽度
      lineWidth: 1.5,
      lineHeightLG: '22px',
      lineHeight: '20px',
      colorTextDescription: '#9e9e9e',
      colorTextDisabled: '#cdcdcd',
      colorBgContainerDisabled: '#f0f1f5',
      fontSizeLG: '14px', // 输入框字体大小
    },
  },
} as any;
</script>

<style scoped lang="less">
.df-textarea {
  &:focus {
    color: #1a1a1a;
    background-color: #ffffff;
  }
  &.ant-input {
    padding: v-bind(padding);
    border-radius: 8px;
    &:not(:focus):hover {
      border-color: transparent;
      background-color: #f0f0f5;
      background-image: linear-gradient(rgba(73, 79, 135, 0.05), rgba(73, 79, 135, 0.05));
    }
    &:focus {
      border-width: 1px;
    }
  }
  :deep(.ant-input) {
    padding: v-bind(padding);
  }

  &.ant-input[disabled] {
    border-color: #cdcdcd;
  }
  &.ant-input-status-error {
    background-color: #ffffff;
  }
}
</style>
